.ant-drawer-content {
  background-color: #113366 !important;
}
.gx-layout-sider-header {
  background-color: #0a2447 !important;
}
.gx-sidebar-content {
  border-right: 0 !important;
}
.gx-card-full .recharts-default-legend li {
  display: inline-flex !important;
  justify-content: center !important;
}
.ant-input-affix-wrapper {
  line-height: 0 !important;
  border-radius: 5px !important;
}
#password,
#reset-password,
#confirm_password {
  padding-left: 12px;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1 {
  height: 102px !important;
}
.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
  padding: 15px 0 15px 30px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input,
.ant-picker.ant-picker-range {
  border-radius: 5px !important;
  height: 36px;
  font-size: 13.33px;
  padding-top: 2px;
}
.ant-picker.ant-picker-range {
  padding-top: 5px;
}
.ant-drawer-close {
  font-size: medium;
}
.gx-bg-transparent {
  background-color: transparent !important;
}
.footer-wrapper-modal {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.footer-row-modal {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  margin-right: 1rem;
}
.gx-cursor-default {
  cursor: default;
}
.gx-layout-sider-scrollbar.gx-h-100.gx-layout-sider-scrollbar-fix {
  height: calc(100vh - 113px) !important;
}
.divider-sidebar-signup {
  position: absolute !important;
  top: calc(100vh - 180px) !important;
}
li.ant-menu-item.ant-menu-item-only-child.divider-sidebar-signup.gx-px-0 {
  padding: 0 !important;
}
.ant-drawer-body {
  overflow-y: hidden !important;
}
@media screen and (max-width: 520px) {
  .footer-wrapper-modal {
    justify-content: center;
  }
  .footer-row-modal {
    width: 100%;
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }
  .gx-layout-sider-scrollbar.gx-h-100.gx-layout-sider-scrollbar-fix {
    height: calc(100vh - 85px) !important;
  }
  .divider-sidebar-signup {
    top: calc(100vh - 150px) !important;
  }
}

.signup_container {
  min-height: 60vh;
  max-height: 800px;
  width: 80vw;
  margin: auto;
}

.ant-upload {
  background-color: "red";
}

.ant-select:hover .ant-select-arrow {
  z-index: 1 !important;
}

.ant-select-arrow {
  z-index: 0 !important;
}
